import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Popup } from '../../../../common/popup/Popup';
import { ButtonRectangular } from '../../../../common/btn/ButtonRectangular';
import { NodeStatusForm } from './nodeStatusForm/NodeStatusForm';
import { PopupHead } from './PopupHead';
import { PopupTitle } from './PopupTitle';
import { NodeDataOutput } from './NodeDataOutput';
import { NodeDataText } from './NodeDataText';
import { toastOptions, checkDataPointValue } from '../../../../../api/functions';
import { SelectNodeStatus } from './SelectNodeStatus';
import { NodeStatusFormNew } from './nodeStatusForm/NodeStatusFormNew';
import { WithoutDataPointCheckbox } from './WithoutDataPointCheckbox';

import {
    addBMSDocNode,
    add_bms_document_node,
    getBMSNodesList,
    updateNodeData,
    nodes_list as nodes_list_state,
    checkCopyNode,
} from '../../../../../redux/slices/bms/bmsSliceDocuments';

const position = {
    left: 0,
    top: 0,
    leftPercent: 0,
    topPercent: 0,
    height: 'auto',
    width: 'auto',
    degree: 0,
    fontSize: '16px',
};
export const CreateNodePopup = ({ setIsOpen, node, docId }) => {
    const { lib_data } = node;
    const { states } = node.lib_data;

    const dispatch = useDispatch();
    const nodes_list = useSelector(nodes_list_state);

    const [libElemTitle, setLibElemTitle] = useState(node.name);
    const [nodeStates, setNodeStates] = useState(node.data?.states ? node.data.states : []);
    const [disabled, setDisabled] = useState(true);
    const [checkPopupFields, setCheckPopupFields] = useState(false);
    const [isUseWithoutDataPoint, setIsUseWithoutDataPoint] = useState(node.data?.isUseWithoutDataPoint ? node.data.isUseWithoutDataPoint : false);

    useEffect(() => {
        if (node.data && node.data.isNew) {
            dispatch(checkCopyNode(node.cn_id));
        }
    }, []);

    useEffect(() => {
        if (!nodes_list.length) {
            dispatch(getBMSNodesList({ docId }));
        }
    }, [nodes_list]);

    useEffect(() => {
        if (isUseWithoutDataPoint) {
            setDisabled(false);
            return;
        } else {
            setDisabled(true);
        }
        // проверка элемента без состояний
        if (states.length === 0 && libElemTitle.length > 0 && !lib_data.hasOwnProperty('subtype')) {
            setDisabled(false);
            // проверка элемента с состояниями
        } else if (states.length !== 0 && libElemTitle.length > 0 && checkPopupFields) {
            setDisabled(false);
            // проверка элемента вывода и текста
        } else if (states.length === 0 && libElemTitle.length > 0 && lib_data.hasOwnProperty('subtype') && checkPopupFields) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [libElemTitle, checkPopupFields, isUseWithoutDataPoint]);

    const nodeOptions = useMemo(
        () =>
            nodes_list.length
                ? nodes_list.map(node => {
                      return {
                          value: node.id,
                          label: node.name,
                          datatype: node.datatype,
                          value_from_data_point: node.value,
                          bitsize: node.bitsize,
                      };
                  })
                : [],
        [nodes_list]
    );

    const clickHandler = async () => {
        // проверка одинаковых значений у точки данных/проверка соответствия min и max
        const error = await checkDataPointValue(nodeStates);
        if (error) {
            return toast.error(error, toastOptions);
        }

        const data = {
            title: libElemTitle,
            states: nodeStates,
            position,
            write_point: nodeStates.length > 0 && nodeStates[0].hasOwnProperty('writePoint') ? nodeStates[0].writePoint.value : '',
            isUseWithoutDataPoint,
        };

        const nodeObj = {
            doc_id: docId,
            node_id: node.id,
            data: JSON.stringify(data),
        };

        if (node.hasOwnProperty('cn_id')) {
            const updNode = {
                ...node,
                title: libElemTitle,
                data: JSON.stringify(data),
                position: JSON.stringify(node.position),
            };
            const nodesArr = [];
            nodesArr.push(updNode);
            const result = await dispatch(updateNodeData({ nodesArr }));
            if (result.error) {
                toast.error('Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.', toastOptions);
                return null;
            }
        } else {
            const { cn_id, width, height } = await dispatch(addBMSDocNode({ nodeObj }));
            const resultNode = {
                cn_id,
                data,
                title: libElemTitle,
                lib_data: node.lib_data,
                lib_title: node.title,
                name: data.title,
                position: { ...position, width: `${width}px`, height: `${height}px`, zIndex: 0 },
            };
            dispatch(add_bms_document_node(resultNode));
        }
        setIsOpen(false);
    };

    if (!lib_data.default_pic) {
        return (
            <Popup isOpen={true} setIsOpen={setIsOpen} className="w-[750px]">
                <div className="text-center p-4">Элемент без изображения добавить на холст нельзя</div>
            </Popup>
        );
    }
    return (
        <Popup
            isOpen={true}
            setIsOpen={setIsOpen}
            className={`w-[920px] max-h-[600px] pb-14 pt-8 ${lib_data.hasOwnProperty('subtype') && lib_data.subtype === 'output' ? '' : 'overflow-auto'}`}
        >
            <PopupTitle title={node.title} setLibElemTitle={setLibElemTitle} />
            {states.length !== 0 && (
                <WithoutDataPointCheckbox isUseWithoutDataPoint={isUseWithoutDataPoint} setIsUseWithoutDataPoint={setIsUseWithoutDataPoint} />
            )}
            {states.length !== 0 && (
                <>
                    {nodeStates.length ? <PopupHead nodeStates={nodeStates} /> : ''}

                    {/* два варианта, так как есть старые схемы, где нет возможности добавлять состояния */}
                    {nodeStates.map(el =>
                        el.id ? (
                            <div key={el.id} className="flex mt-3 ">
                                <NodeStatusFormNew
                                    setCheckPopupFields={setCheckPopupFields}
                                    nodeOptions={nodeOptions}
                                    node={el}
                                    setNodeStates={setNodeStates}
                                    nodeStates={nodeStates}
                                    statusInfo={nodeStates.find(ell => ell.id === el.id)}
                                    isUseWithoutDataPoint={isUseWithoutDataPoint}
                                />
                            </div>
                        ) : (
                            <div key={el.name} className="flex mt-3 ">
                                <NodeStatusForm
                                    setCheckPopupFields={setCheckPopupFields}
                                    nodeOptions={nodeOptions}
                                    node={el}
                                    setNodeStates={setNodeStates}
                                    nodeStates={nodeStates}
                                    statusInfo={nodeStates.find(ell => ell.name === el.name)}
                                    isUseWithoutDataPoint={isUseWithoutDataPoint}
                                />
                            </div>
                        )
                    )}
                    <SelectNodeStatus setNodeStates={setNodeStates} states={states} isDisabled={isUseWithoutDataPoint || nodeStates.length > 9} />
                </>
            )}
            {lib_data.hasOwnProperty('subtype') && lib_data.subtype === 'output' && (
                <NodeDataOutput setNodeStates={setNodeStates} setCheckPopupFields={setCheckPopupFields} nodeOptions={nodeOptions} node={node} />
            )}
            {node.lib_data.hasOwnProperty('subtype') && lib_data.subtype === 'text' && (
                <NodeDataText setNodeStates={setNodeStates} setCheckPopupFields={setCheckPopupFields} node={node} />
            )}
            <ButtonRectangular disabled={disabled} className="w-40 shrink-0 ml-auto mr-auto mt-10 justify-center" onClick={clickHandler}>
                Применить
            </ButtonRectangular>
        </Popup>
    );
};
