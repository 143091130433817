//перевод объекта в массив
export const objectToArray = obj => Object.keys(obj).map(key => obj[key]);

//ввод с клавиатуры только целых чисел
export const inputOnlyInteger = str => +str.trim().replace(/[^0-9]/g, '');

//ввод с клавиатуры только целых чисел в интервале
export const inputOnlyIntegerInterval = (str, min, max) => {
    let res = str.trim().replace(/[^0-9]/g, '');
    if (!res) {
        return res;
    }
    if (res < min) {
        res = min;
    }
    if (res > max) {
        res = max;
    }

    return +res;
};

//функция-слушатель для кликов вне блока
export const listenForOutsideClick = (listening, setListening, blockRef, callback) => () => {
    if (listening) return;
    setListening(true);

    document.addEventListener('click', e => {
        const cur = blockRef.current;
        if (!blockRef.current) return;

        const node = e.target;
        if (cur.contains(node)) return;
        callback();
    });
};

//настройки для toast по умолчанию
export const toastOptions = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
};

// go to element
export const goToElement = id => {
    document.getElementById('wrapper').scrollTo({
        top: document.getElementById(id).offsetTop,
        behavior: 'smooth',
    });
};

// проверка одинаковых значений у ноды/проверка корректности min и max значений
export const checkDataPointValue = states => {
    for (let i = 0; i < states.length; i++) {
        const nodeI = states[i];
        if (!nodeI.bit) {
            for (let j = i + 1; j < states.length; j++) {
                const nodeJ = states[j];
                if (nodeI.node === nodeJ.node && nodeI.value === nodeJ.value) {
                    return 'Нельзя добавить два или более одинаковых значений у точки данных';
                }
            }
        } else {
            for (let j = i + 1; j < states.length; j++) {
                const nodeJ = states[j];
                if (nodeI.node === nodeJ.node && nodeI.bitValue === nodeJ.bitValue) {
                    return 'Нельзя добавить два или более одинаковых побитных значений у точки данных';
                }
            }
        }
    }

    // проверка min и max
    for (let i = 0; i < states.length; i++) {
        const node = states[i];
        if (node.range) {
            if (Number(node.value) >= Number(node.valueMax)) {
                return 'Минимальное значение не может быть больше или равно максимальному';
            }
        }
    }
};
// получение рандомного цвета в hex формате
export const getColor = () => (Math.random().toString(16) + '000000').substring(2, 8).toUpperCase();

// Throw Exception
export const throwException = (message, options = {}) => {
    throw new Error(message, options);
};

// Добавить уникальные элементы в массив объектов
export const addUniqueElements = (originalArray, newElements, key) => {
    const existingKeys = originalArray.map(item => item[key]);

    newElements.forEach(element => {
        if (!existingKeys.includes(element[key])) {
            originalArray.push(element);
            existingKeys.push(element[key]);
        }
    });

    return originalArray;
};

// Создать файл из url
export const getFileFromUrl = async (url, name = 'temp.jpg', defaultType = 'image/jpeg') => {
    const response = await fetch(url, { cache: 'no-store' });
    const data = await response.blob();
    const file = new File([data], name, {
        type: data.type || defaultType,
    });

    return Object.assign(file, {
        preview: url,
        key: `${file.name}`,
    });
};
